import React, { useEffect, useState, useRef } from 'react'
import { graphql, navigate, Link } from 'gatsby'
import styled from 'styled-components'
import { IoMdSearch } from 'react-icons/io'
import { BsChevronRight } from 'react-icons/bs'
import { theme } from '@/styles/theme'
import { Layout } from '@/components/layouts/Layout'
import { Seo } from '@/components/Seo'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { ListItems } from '@/components/layouts/ListItems'

import type {
  MicrocmsFaqConnection,
  MicrocmsFaqCategoryConnection,
  MicrocmsFaqMediumCategoryConnection,
  MicrocmsFaqEdge,
} from '@/interfaces/graphqlTypes'
import { MainWrapper, TitleWrapper } from '@/styles/common'

export const query = graphql`
  query {
    allMicrocmsFaq {
      edges {
        node {
          id
          question
          faqId
          mediumCategory {
            id
          }
          answerBody {
            fieldId
            answerNormalText
            answerCautionText
            answerHintText
            answerReferenceText
            answerTableText
          }
        }
      }
    }
    allMicrocmsFaqCategory {
      edges {
        node {
          category
          faqCategoryId
        }
      }
    }
    allMicrocmsFaqMediumCategory {
      edges {
        node {
          faqMediumCategoryId
          mediumCategory
          majorCategory {
            category
            id
          }
        }
      }
    }
  }
`

type Query = {
  allMicrocmsFaq: MicrocmsFaqConnection
  allMicrocmsFaqCategory: MicrocmsFaqCategoryConnection
  allMicrocmsFaqMediumCategory: MicrocmsFaqMediumCategoryConnection
}

export default function faq({ data }: { data: Query }): JSX.Element {
  const [searchText, setSearchText] = useState<string>('')
  const searchInputRef = useRef<HTMLInputElement | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filterFaqList, setFilterFaqList] = useState<MicrocmsFaqEdge[]>([])
  const [filterFaqCount, setFilterFaqCount] = useState<number>(-1)
  const [showCategory, setShowCategory] = useState<boolean>(true)

  const breadCrumbs: {
    name: string
    path?: string
  }[] = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'よくあるご質問',
      path: '/faq/',
    },
  ]

  const search = typeof window !== 'undefined' && window.location.search

  if (search) {
    breadCrumbs.push({
      name: '検索結果',
    })
  }

  useEffect(() => {
    if (!location.search) return

    const query = location.search
    setSearchQuery(decodeURI(query).split('?')[1].split('=')[1])
  })

  useEffect(() => {
    if (!searchQuery) return
    setFilterFaqList([])
    setFilterFaqCount(-1)

    const filterdQuestion = data.allMicrocmsFaq.edges.filter((faq) =>
      // タイトル(question) or 回答(answerBody)に検索文字列が含まれる質問に絞り込む
      faq.node.question?.includes(searchQuery) ||
      faq.node.answerBody?.findIndex((answers) =>
        // 回答からHTMLタグを取り除いた後、検索文字列と部分一致するか判定する
        (
          answers?.answerNormalText +
          answers?.answerCautionText +
          answers?.answerHintText +
          answers?.answerReferenceText +
          answers?.answerTableText
        ).replace(/<.+?>/g, "").includes(searchQuery)
      ) !== -1
    ).sort((faq) => 
        // タイトル(question)に検索文字列が含まれる場合、上位に表示する
        faq.node.question?.includes(searchQuery) ? -1 : 1
    )

    setFilterFaqList(filterdQuestion)
    setFilterFaqCount(filterdQuestion.length)
    if (filterdQuestion.length > 0) {
      setShowCategory(false)
    } else {
      setShowCategory(true)
    }
  }, [searchQuery])

  useEffect(() => {
    const faqLink = document.querySelectorAll(`a[href="/faq/"]`)
    faqLink.forEach((link) =>
      link.addEventListener('click', () => {
        if (searchInputRef.current) {
          searchInputRef.current.value = ''
        }
        setShowCategory(true)
      })
    )
  }, [])

  useEffect(() => {
    if (!showCategory) return
    const accordion = document.querySelectorAll('.accordion')

    for (let i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener('click', () => {
        accordion[i].classList.toggle('open')

        for (let j = 0; j < accordion.length; j++) {
          if (accordion[i] !== accordion[j]) {
            accordion[j].classList.remove('open')
          }
        }
      })
    }
  }, [showCategory])

  const searchFaq = () => {
    if (filterFaqCount > 0) {
      setShowCategory(false)
    } else {
      setShowCategory(true)
    }

    searchInputRef.current?.blur()

    navigate(`/faq?q=${searchText}`)
  }

  return (
    <Layout appHide>
      <Seo
        title="よくあるご質問"
        canonical="https://su-pay.jp/faq/"
        description="SU-PAYについてのよくあるご質問はこちら。"
      />
      <Title as="h1" bg={true}>
        よくあるご質問
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        {search && filterFaqCount >= 0 && (
          <>
            <TitleWrapper pcMarginBottom={50}>
              <Title as="h2" color={theme.colors.supayBlue}>
                {`“${searchQuery}”の検索結果：${filterFaqCount}件`}
              </Title>
            </TitleWrapper>
            {filterFaqCount === 0 && (
              <NoSearchResult>
                該当する検索結果は見つかりませんでした。
              </NoSearchResult>
            )}
            <ul>
              {filterFaqList.map((faq) => {
                return (
                  <ListItems
                    key={faq.node.id}
                    link={`/faq/${faq.node.mediumCategory?.id!}/${faq.node
                      .faqId!}/`}
                    item={faq.node.question!}
                  />
                )
              })}
            </ul>
          </>
        )}
        <TitleWrapper pcMarginBottom={50}>
          <Title as="h2" color={theme.colors.supayBlue}>
            キーワードから探す
          </Title>
        </TitleWrapper>
        <SearchSectionWrapper>
          <SearchBoxWrapper>
            <Search
              type="text"
              placeholder="キーワードを入力"
              onChange={(e) => {
                setSearchText(encodeURI(e.target.value))
              }}
              onKeyPress={(e) => {
                if (e.key !== 'Enter' || e.target.value === '') return
                searchFaq()
              }}
              ref={searchInputRef}
              enterKeyHint="search"
            />
            <SearchIcon />
          </SearchBoxWrapper>
          <SearchButton onClick={searchFaq} disabled={searchText === ''}>
            検索
          </SearchButton>
        </SearchSectionWrapper>
        {showCategory && (
          <>
            <TitleWrapper
              pcMarginTop={120}
              spMarginTop={60}
              pcMarginBottom={50}
            >
              <Title as="h2" color={theme.colors.supayBlue}>
                カテゴリから探す
              </Title>
            </TitleWrapper>
            <ul>
              {data.allMicrocmsFaqCategory.edges.map((category) => (
                <FaqAccordion
                  key={category.node.faqCategoryId}
                  className="accordion"
                >
                  <FaqQuestion>
                    <h3>{category.node.category}</h3>
                    <Icon>
                      <span />
                      <span />
                    </Icon>
                  </FaqQuestion>
                  <FaqAnswer>
                    <FaqAnswerInner>
                      {data.allMicrocmsFaqMediumCategory.edges
                        .filter(
                          (mediumCategory) =>
                            mediumCategory.node.majorCategory?.id ===
                            category.node.faqCategoryId
                        )
                        .map((mediumCategory) => (
                          <FaqLink key={mediumCategory.node.mediumCategory}>
                            <FaqInner
                              to={`/faq/${mediumCategory.node.faqMediumCategoryId}/`}
                            >
                              <h4>{mediumCategory.node.mediumCategory}</h4>
                              <Arrow />
                            </FaqInner>
                          </FaqLink>
                        ))}
                    </FaqAnswerInner>
                  </FaqAnswer>
                </FaqAccordion>
              ))}
            </ul>
          </>
        )}
      </MainWrapper>
    </Layout>
  )
}

const NoSearchResult = styled.p`
  font-size: 24px;

  @media screen and (max-width: 1047px) {
    font-size: 16px;
  }
`

const SearchSectionWrapper = styled.div`
  display: flex;
`

const SearchBoxWrapper = styled.div`
  position: relative;
  width: 100%;
`

const Search = styled.input`
  display: block;
  width: 100%;
  height: 64px;
  padding: 22px 25px 22px 62px;
  border: none;
  background-color: rgba(0, 70, 155, 0.1);
  border-radius: 6px 0 0 6px;
  font-size: 18px;
  line-height: 1em;

  ::placeholder {
    color: ${(props) => props.theme.colors.supayBlue};
    opacity: 0.6;
  }

  @media screen and (max-width: 1047px) {
    padding: 9px 12px 9px 40px;
    font-size: 16px;
    height: 40px;
    border-radius: 6px;
  }
`

const SearchIcon = styled(IoMdSearch)`
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);

  font-size: 30px;
  color: ${(props) => props.theme.colors.supayBlue};
  opacity: 0.6;

  @media screen and (max-width: 1047px) {
    font-size: 21px;
    left: 12px;
  }
`

const SearchButton = styled.button`
  color: white;
  background-color: ${theme.colors.supayBlue};

  width: 100px;
  font-size: 18px;
  line-height: 1em;
  border-radius: 0 6px 6px 0;

  transition: all 0.3s ease-in-out;

  :disabled {
    background-color: ${theme.colors.grey};
  }

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const FaqAnswer = styled.div`
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
`

const Icon = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgba(0, 70, 155, 0.8);

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    display: block;
    height: 2px;
    width: 16px;
    background-color: #fff;
    transition: all 0.5s;

    &:nth-child(2) {
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
  }
`

const FaqAccordion = styled.li`
  background-color: rgba(0, 70, 155, 0.1);
  border-radius: 10px;
  cursor: pointer;

  & + & {
    margin-top: 22px;
  }

  &.open {
    ${FaqAnswer} {
      max-height: 100dvh;
      max-height: 100vh;
      opacity: 1;
    }

    ${Icon} span {
      &:nth-child(1) {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
      }
    }
  }
`

const FaqQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 64px;
  padding: 20px 20px 20px 18px;

  h3 {
    font-size: 18px;
    line-height: 1em;
  }

  @media screen and (max-width: 1047px) {
    height: 56px;
    padding: 15px 14px 15px 22px;

    h3 {
      font-size: 16px;
      line-height: 1em;
    }
  }
`

const FaqLink = styled.li`
  border-radius: 8px;
  background-color: rgba(0, 70, 155, 0.1);
  height: 64px;

  & + & {
    margin-top: 16px;
  }

  @media screen and (max-width: 1047px) {
    height: auto;
  }
`

const FaqInner = styled(Link)`
  display: flex;
  align-items: center;
  padding: 21px 25px;
  justify-content: space-between;
  -webkit-tap-highlight-color: initial;
  cursor: pointer;
  line-height: 27px;
  color: ${(props) => props.theme.colors.textColor};
  height: 64px;

  :hover {
    opacity: 0.6;
  }

  h4 {
    font-size: 18px;
    font-weight: normal;
  }

  @media screen and (max-width: 1047px) {
    padding: 13px 15px 13px 18px;
    height: 48px;

    h4 {
      font-size: 16px;
      line-height: 1em;
      padding-right: 12px;
      width: calc(100% - 24px);
    }
  }
`

const Arrow = styled(BsChevronRight)`
  font-size: 30px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.supayBlue};

  @media screen and (max-width: 1047px) {
    font-size: 24px;
  }
`

const FaqAnswerInner = styled.ul`
  padding: 16px 20px 20px;

  @media screen and (max-width: 1047px) {
    padding: 4px 12px 12px;
  }
`
