import { Faq } from '@/interfaces/faq'
import { Link } from 'gatsby'
import React from 'react'
import { BsChevronRight } from 'react-icons/bs'
import styled from 'styled-components'
import { QABadge } from '../elements/QABadge'

type ListItemsProps = {
  item: string
  link: string
  showBadge?: boolean
  date:string
}


export const ListItems = ({ item, link, showBadge = true,date }: ListItemsProps) => {
  return (
    <ListItem>
      {date && <DateBadge>{date}</DateBadge>}
      <ListItemLink to={link}>
        <ListItemInner>
          {showBadge && <QABadge type="Q" color="blue"></QABadge>}
          <p>{item}</p>
        </ListItemInner>
        <Arrow />
      </ListItemLink>
    </ListItem>
  )
}

const DateBadge = styled.p`
  padding-top: 12px;
  padding-left: 22px;
  margin-bottom: -17px;
  font-size: 14px;
  color: #909090;
`

const ListItem = styled.li`
  background-color: rgba(0, 70, 155, 0.1);
  border-radius: 10px;
  width: 100%;

  & + & {
    margin-top: 22px;
  }
`

const ListItemLink = styled(Link)`
  width: 100%;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    opacity: 0.6;
  }

  @media screen and (max-width: 1047px) {
    padding: 13px 16px;
  }
`

const ListItemInner = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
  p {
    width: calc(100% - 30px);
    padding: 0 12px;
    font-size: 18px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.textColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (max-width: 1047px) {
    p {
      font-size: 16px;
      line-height: 20px;
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
`

const Arrow = styled(BsChevronRight)`
  font-size: 30px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.supayBlue};
`
